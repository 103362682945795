import { useUserStore } from '~/store/user';
import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { Redirect } from "@shopify/app-bridge/actions";
import { onFID, onLCP, onCLS, onINP } from "web-vitals";

const shopifyAppLoginRedirectUrl = `${process.env.BASE_BACKEND_URL}/shopify/login`;

export default ({ route, $axios }, _inject) => {
  const query = route?.query || {}
  const userStore = useUserStore();
  userStore.shopifyHost = query.host || userStore.shopifyHost;

  if(userStore.shopifyHost && query.embedded === '1') {
    userStore.shopifyApp = createApp({
      apiKey: process.env.SHOPIFY_APP_CLIENT_ID,
      host: userStore.shopifyHost,
    });

    userStore.shopifyRedirect = Redirect.create(userStore.shopifyApp);

    onLCP((metric) => { console.log('Sales POP! Web Vitals LCP:', metric) });
    onFID((metric) => { console.log('Sales POP! Web Vitals FID:', metric) });
    onCLS((metric) => { console.log('Sales POP! Web Vitals CLS:', metric) });
    onINP((metric) => { console.log('Sales POP! Web Vitals INP:', metric) });
  }

  query.token ? userStore.setAuthToken(query.token) : userStore.setAuthTokenFromSessionStorage();
  query.admin_token ? userStore.setAdminToken(query.admin_token) : userStore.setAdminTokenFromSessionStorage();

  $axios.interceptors.request.use(async (config) => {
    const sessionToken = userStore.shopifyApp ? await getSessionToken(userStore.shopifyApp) : null;

    const authHeaders = {}

    if(userStore.authToken) authHeaders['Auth-Token'] = userStore.authToken
    if(userStore.adminToken) authHeaders['X-Admin-Token'] = userStore.adminToken
    if(sessionToken) authHeaders['X-Shopify-Session-Token'] = sessionToken

    config.headers = {
      ...config.headers,
      'Access-Control-Allow-Origin': '*',
      ...authHeaders
    }

    return config;
  });

  if(query.embedded !== '1' && userStore.shopifyHost) {
    window.top.location.assign(`${shopifyAppLoginRedirectUrl}${window.location.search}`)
  } else if(query.embedded !== "1" && !userStore.authToken && !userStore.adminToken) {
    window.top.location.assign(shopifyAppLoginRedirectUrl)
  }
}
